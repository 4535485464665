import {
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button as BootStrapButton } from "@themesberg/react-bootstrap";
import { Divider, Input, Modal, Select, Space, Spin } from "antd";
import { useContext, useEffect, useRef, useState } from "react";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";
import { getTimeFromSeconds } from "../helpers/utils";

const { Option } = Select;
const { confirm, info } = Modal;

const Tracking = () => {
  const auth = useContext(AuthContext);
  const inputRef = useRef(null);

  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTaskName, setNewTaskName] = useState(null);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [managingProject, setManagingProject] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  const startTracking = async (task) => {
    try {
      if (typeof task === "number" || typeof task === "string") {
        setTimeout(() => {
          Modal.destroyAll();
        }, 1000);
        const { err } = await Data.startTracking(
          { task, project: selectedProject },
          auth?.token
        );
        if (err) return;
        await auth?.getUserLatestTask();

        return;
      }
      if (auth?.latestTask && !auth?.latestTask?.stopped_at) {
        setIsLoading(true);
        await Data.stopTracking(auth?.token);
        setIsLoading(false);
        setHours("00");
        setMinutes("00");
        setSeconds("00");
        await auth?.getUserLatestTask();
        return;
      }
      setIsLoading(true);
      await Data.startTracking(
        { task: selectedTask, project: selectedProject },
        auth?.token
      );

      setIsLoading(false);
      await auth?.getUserLatestTask();
    } catch (err) {
      console.log(err);
    }
  };

  const endTrackingForDay = async () => {
    setIsLoading(true);
    await Data.stopTracking(auth?.token);
    setIsLoading(false);
    setHours("00");
    setMinutes("00");
    setSeconds("00");
    await auth?.getUserLatestTask();

    info({
      content: "Successfully ended for the day",
    });
  };

  const selectProject = (id) => {
    updateTasks(id);
    setSelectedProject(id);
    setSelectedTask(null);
  };

  const selectTask = (id) => {
    setSelectedTask(id);
    setNewTaskName("");

    if (
      auth?.latestTask &&
      !auth?.latestTask?.stopped_at &&
      auth?.latestTask?.task !== id
    ) {
      confirm({
        icon: <ExclamationCircleFilled />,
        title: "Are you sure you want to switch the task?",
        onOk: () => startTracking(id),
        maskClosable: true,
      });
    }
  };

  const onNewTaskNameChange = (e) => {
    setNewTaskName(e.target.value);
  };

  const onEnterPress = (e) => {
    if (
      !(
        !newTaskName ||
        !newTaskName?.trim() ||
        tasks?.filter(
          (task) =>
            task?.name?.toLowerCase()?.trim() ===
            newTaskName?.toLowerCase()?.trim()
        ).length > 0
      ) &&
      e?.key === "Enter"
    ) {
      e.stopPropagation();
      createNewTask(e);
    }
  };

  const createNewTask = async (e) => {
    e.preventDefault();

    const { task, err } = await Data.createNewTask(
      { name: newTaskName, project: selectedProject },
      auth?.token
    );

    if (err) return console.log(err);
    setTasks([
      ...tasks,
      { value: task?.id, label: task?.name, createdby: auth?.userId },
    ]);
    setSelectedTask(task?.id);
    setNewTaskName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const updateTasks = async (project, keyword) => {
    try {
      if (project) {
        setTasksLoading(true);
        const { tasks, err } = await Data.getTasksByProject(
          { project, keyword },
          auth?.token
        );

        if (err) {
          setTasksLoading(false);
          return setErr(err);
        }

        setTasks(tasks);
        setTasksLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateProjects = async (keyword) => {
    try {
      const { projects, err } = await Data.getProjects(
        { keyword, shouldPopulate: false },
        auth?.token
      );
      if (err) return setErr(err);

      setProjects(projects);
    } catch (err) {
      console.log(err);
    }
  };

  const updateManagingProject = async () => {
    try {
      const { project, err } = await Data.getProjectManagedByUser(auth?.token);
      if (err) return setErr(err);

      setManagingProject(project);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      updateProjects();
      updateManagingProject();
    }
  }, [auth?.token]);

  useEffect(() => {
    const { hours, minutes, seconds: s } = getTimeFromSeconds(auth?.seconds);
    setSeconds(s);
    setMinutes(minutes);
    setHours(hours);
  }, [auth?.seconds]);

  useEffect(() => {
    const projectExist = projects.filter(
      (p) => p.value === auth?.latestTask?.project
    )[0];
    if (!projectExist) {
      setProjects((projects) => {
        return [
          ...projects,
          {
            value: auth?.latestTask?.project,
            label: auth?.latestTask?.projectname,
          },
        ];
      });
    }
    setSelectedProject(auth?.latestTask?.project);
    updateTasks(auth?.latestTask?.project);
    const taskExist = tasks.filter(
      (t) => t.value === auth?.latestTask?.task
    )[0];
    if (!taskExist) {
      setTasks([
        ...tasks,
        {
          value: auth?.latestTask?.task,
          label: auth?.latestTask?.taskname,
        },
      ]);
    }
    setSelectedTask(auth?.latestTask?.task);
  }, [auth?.latestTask]);

  const onDeleteTask = async (id) => {
    try {
      await Data.deleteTask(id, auth?.token);
      const filteredTasks = tasks.filter((t) => t.value != id);
      setTasks(filteredTasks);

      if (selectedTask === id) {
        setSelectedTask(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setUserBreakStatus = async () => {
    try {
      setClickCount(1);
      await Data.setUserBreakStatus(auth?.token);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      auth?.socket?.on(`task-started-${auth?.uid}`, async () => {
        await auth?.getUserLatestTask();
      });
      auth?.socket?.on(`task-stopped-${auth?.uid}`, async () => {
        await auth?.getUserLatestTask();
      });
    }

    return () => {
      auth?.socket?.off("task-started");
      auth?.socket?.off("task-stopped");
      auth?.socket?.off(`task-started-${auth?.uid}`);
      auth?.socket?.off(`task-stopped-${auth?.uid}`);
    };
  }, [auth?.socket]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Space style={{ float: "right" }}>
      <Select
        value={selectedProject}
        showSearch
        dropdownMatchSelectWidth={false}
        placeholder="Select Project"
        onSearch={updateProjects}
        onSelect={selectProject}
        filterOption={false}
        options={projects}
        style={{ minWidth: "100px" }}
      ></Select>
      <Select
        value={selectedTask}
        showSearch
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        placeholder="Select Task"
        onSearch={(keyword) => updateTasks(selectedProject, keyword)}
        onSelect={selectTask}
        filterOption={false}
        loading={tasksLoading}
        disabled={!selectedProject}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  maxLength={75}
                  placeholder="Enter Task"
                  ref={inputRef}
                  value={newTaskName}
                  onChange={onNewTaskNameChange}
                  onKeyDown={onEnterPress}
                />
                <BootStrapButton
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={createNewTask}
                  disabled={
                    !newTaskName ||
                    !newTaskName.trim() ||
                    tasks.filter(
                      (task) =>
                        task?.name?.toLowerCase().trim() ===
                        newTaskName?.toLowerCase().trim()
                    ).length > 0
                  }
                >
                  Add Task
                </BootStrapButton>
              </Space>
            </>
          );
        }}
        style={{ minWidth: "100px" }}
      >
        {tasks.map((task) => {
          return (
            <Option value={task.value} label={task.label}>
              <span>{task.label}</span>
              {(task.createdby == auth?.userId ||
                auth?.isadmin === true ||
                (auth?.isprojectmanager === true &&
                  managingProject?.id === task.project)) && (
                <span style={{ float: "right" }}>
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteTask(task.value);
                    }}
                  />
                </span>
              )}
            </Option>
          );
        })}
      </Select>
      <span>
        {hours}:{minutes}:{seconds}
      </span>

      <BootStrapButton
        disabled={!selectedProject || !selectedTask}
        onClick={startTracking}
        className={
          auth?.latestTask && !auth?.latestTask?.stopped_at
            ? "stop-button"
            : "start-button"
        }
      >
        {!auth?.latestTask || (auth?.latestTask && auth?.latestTask?.stopped_at)
          ? "Start"
          : "Stop"}
      </BootStrapButton>

      {!auth?.latestTask ||
        (auth?.latestTask &&
          auth?.latestTask?.stopped_at &&
          clickCount === 0 && (
            <BootStrapButton onClick={setUserBreakStatus}>
              On a Break?
            </BootStrapButton>
          ))}

      <BootStrapButton onClick={endTrackingForDay}>End of day?</BootStrapButton>
    </Space>
  );
};

export default Tracking;
