import { Result, Select, Space } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../context/auth-context";
import * as Data from "../helpers/server";

import { Button } from "@themesberg/react-bootstrap";
import RangePicker from "../components/DateRangePicker";
import Loader from "../components/Loader";
import UserActivity from "../components/UserActivity";
import UserProfile from "../components/UserProfile";

const UserDetails = () => {
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const getUserActivity = async ({
    page,
    pageSize,
    startDate,
    endDate,
    project,
  }) => {
    try {
      if (auth?.token) {
        setLoading(true);
        const { tasks, total, err } = await Data.getUserActivity(
          {
            id,
            page,
            pageSize,
            startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            project,
          },
          auth?.token
        );
        setLoading(false);
        if (err) return console.log(err);
        setTasks(tasks);
        setTotal(total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDetails = async (id) => {
    try {
      if (auth?.token && id) {
        setIsProfileLoading(true);
        const { user, tasksCount, projectsCount } = await Data.getUserDetails(
          id,
          auth?.token
        );
        setUser(user);
        setTasksCount(tasksCount);
        setProjectsCount(projectsCount);
        setIsProfileLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateProjects = async (keyword) => {
    try {
      setProjectsLoading(true);
      const { projects, err } = await Data.getProjects(
        { keyword, shouldPopulate: false },
        auth?.token
      );
      setProjectsLoading(false);
      if (err) return;

      setProjects(projects);
    } catch (err) {
      console.log(err);
    }
  };

  const onDateRangeChange = async (e) => {
    const range = e.currentTarget.value.split(" - ");
    const startDate = moment(range[0], "DD.MM.YYYY").startOf("day");
    const endDate = moment(range[1], "DD.MM.YYYY").endOf("day");

    setStartDate(startDate);
    setEndDate(endDate);
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
    });
  };

  const selectProject = (id) => {
    setSelectedProject(id);
    setPage(1);

    getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: id,
    });
  };

  useEffect(() => {
    if (auth?.token && id) {
      getUserDetails(id);
      getUserActivity({
        page,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
      });

      updateProjects();
    }
  }, [id, auth?.token]);

  const getUserTotalTrackedTime = async ({ startDate, endDate, id }) => {
    try {
      setIsGraphLoading(true);
      const { tracking, err } = await Data.getTotalTrackedTime(
        {
          startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          id,
        },
        auth?.token
      );
      setIsGraphLoading(false);
      if (err) return console.log(err);
      setGraphData(tracking);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token && id && startDate && endDate)
      getUserTotalTrackedTime({ startDate, endDate, id });
  }, [auth?.token, startDate, endDate, id]);

  const onPaginationStateChange = async (pageNumber, pageSizeNumber) => {
    setPage(pageNumber);
    setPageSize(pageSizeNumber);
    getUserActivity({
      page: pageNumber,
      pageSize: pageSizeNumber,
      startDate,
      endDate,
      project: selectedProject,
    });
  };

  const startTaskTimer = async (task, project) => {
    if (auth?.token) {
      setIsGraphLoading(true);
      setLoading(true);

      const { message, err } = await Data.startTracking(
        { task, project },
        auth?.token
      );

      if (err) return console.log(err);

      await auth?.getUserLatestTask();
      await getUserActivity({
        page,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
      });
      setLoading(false);
      await getUserTotalTrackedTime({ startDate, endDate, id });
    }
  };

  const stopTaskTimer = async () => {
    setLoading(true);
    setIsGraphLoading(true);
    const { message, err } = await Data.stopTracking(auth?.token);

    if (err) return console.log(err);

    await auth?.getUserLatestTask();
    await getUserActivity({
      page,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
    });
    setLoading(false);

    await getUserTotalTrackedTime({ startDate, endDate, id });
  };

  const clearProject = () => {
    setSelectedProject(null);
    setPage(1);
    getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: null,
    });
  };

  return (
    <main className="content container-new">
      {!user && isProfileLoading ? (
        <Loader />
      ) : user && user?.isdeleted ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the user you are checking does not exist."
          extra={
            <Button type="primary" onClick={() => navigate("/dashboard")}>
              Back Home
            </Button>
          }
        />
      ) : (
        <>
          <UserProfile
            user={user}
            tasksCount={tasksCount}
            projectsCount={projectsCount}
            isProfileLoading={isProfileLoading}
          />
          <Space>
            <Select
              allowClear
              onClear={clearProject}
              value={selectedProject}
              showSearch
              dropdownMatchSelectWidth={false}
              placeholder="Select Project"
              onSearch={updateProjects}
              onSelect={selectProject}
              filterOption={false}
              options={projects}
              loading={projectsLoading}
              style={{ minWidth: "100px" }}
            ></Select>
            <RangePicker
              onChange={onDateRangeChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Space>
          <UserActivity
            user={user}
            auth={auth}
            setTasks={setTasks}
            tasks={tasks}
            loading={loading}
            onPaginationStateChange={onPaginationStateChange}
            page={page}
            pageSize={pageSize}
            total={total}
            latestTask={auth?.latestTask}
            startTaskTimer={startTaskTimer}
            stopTaskTimer={stopTaskTimer}
            graphData={graphData}
            isGraphLoading={isGraphLoading}
          />
        </>
      )}
    </main>
  );
};

export default UserDetails;
