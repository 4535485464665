import moment from "moment";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import io from "socket.io-client";

import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import { PagesRoutes } from "./routes";

import { useEffect, useState } from "react";
import Loader from "./components/Loader";
import Navbars from "./components/Navbars";
import Sidebar from "./components/sidebar";
import AxolotlOwnerSignIn from "./pages/AxolotlOwnerSignIn";
import Dashboard from "./pages/Dashboard";
import HomePage from "./pages/HomePage";
import Policy from "./pages/HomePage/Policy/Policy";
import Terms from "./pages/HomePage/Terms/Terms";
import Projects from "./pages/Projects";
import Settings from "./pages/Settings";
import SlackAuthRedirectInstall from "./pages/SlackOauthRedirectInstall";
import SlackAuthRedirectLogin from "./pages/SlackOauthRedirectLogin";
import TeamDetails from "./pages/TeamDetails";
import Teams from "./pages/Teams";
import UserActivityDashboard from "./pages/UserActivityDashboard";
import Users from "./pages/Users";

import * as Data from "./helpers/server";
import { getTimeFromSeconds } from "./helpers/utils";
import AxolotlDashboard from "./pages/AxolotlDashboard";
import CustomEmoji from "./pages/CustomEmoji";
import HrPage from "./pages/HrPage";
import LiveDashboard from "./pages/LiveDashboard";
import Notifications from "./pages/Notifications";
import TimeEditRequests from "./pages/TimeEditRequests";
import UserDetails from "./pages/UserDetails";

const App = () => {
  const {
    token,
    userId,
    name,
    image,
    email,
    isadmin,
    isprojectmanager,
    isteammanager,
    uid,
    isAppInstalled,
    login,
    logout,
    workspaceId,
    breakstatus,
  } = useAuth();
  const navigate = useNavigate();

  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
  const [latestTask, setLatestTask] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const authToken = localStorage.getItem("token");
  const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";

  const updateNotifications = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const { count } = await Data.getUserNotifications(
          { type: "unread" },
          token
        );
        setIsLoading(false);
        setUnreadNotificationsCount(count);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUserLatestTask = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const { task, err } = await Data.getUserLatestTask({}, token);
        setIsLoading(false);
        if (err) return console.log(err);

        if (task && !task.stopped_at) {
          const endTime = moment();
          const diff = endTime.diff(moment(task?.started_at), "seconds");

          setSeconds(diff);
        } else {
          setSeconds(0);
          document.title = "Axolotl";
        }

        setLatestTask(task);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getPendingEditRequestCount = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const { count } = await Data.getTimeEditRequestsCount(token, {
          status: "pending",
        });
        setIsLoading(false);

        setPendingRequestsCount(count);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (latestTask && !latestTask.stopped_at)
        setSeconds((seconds) => seconds + 1);
      else setSeconds(0);
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [latestTask]);

  useEffect(() => {
    if (seconds) {
      const { hours, minutes, seconds: s } = getTimeFromSeconds(seconds + 1);
      document.title = `${hours}:${minutes}:${s}`;
    } else document.title = "Axolotl";
  }, [seconds]);

  useEffect(() => {
    if (token) {
      const getUserDetails = async () => {
        try {
          const { user } = await Data.getMeDetails(token);
          if (user.isadmin !== isadmin) logout();
          else {
            login(
              user?.id,
              token,
              user?.name,
              user?.email,
              user?.image,
              user?.isadmin,
              user?.isprojectmanager,
              user?.uid,
              user?.isAppInstalled,
              user?.workspace_id,
              user?.breakstatus,
              user?.isteammanager
            );
          }
        } catch (err) {
          console.log(err);
        }
      };

      getUserDetails();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const socket = io.connect(`https://${window.location.hostname}`, {
        reconnectionDelayMax: 10000,
        auth: { token },
        query: {},
      });
      setSocket(socket);

      updateNotifications();
      getUserLatestTask();
      getPendingEditRequestCount();
    }
  }, [token]);

  useEffect(() => {
    socket?.on(`account-deleted-${uid}`, () => {
      logout();
      navigate(PagesRoutes.HomePage.path);
    });

    return () => {
      socket?.off(`account-deleted-${uid}`);
    };
  }, [socket, uid, logout]);

  if (isLoading) return <Loader />;

  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token,
          userId: userId,
          name,
          image,
          email,
          isadmin,
          isprojectmanager,
          isteammanager,
          uid,
          isAppInstalled,
          workspaceId,
          breakstatus,
          latestTask,
          getUserLatestTask,
          seconds,
          login: (
            uid,
            name,
            image,
            email,
            selectedPlan,
            remainingCharacters,
            totalCharacters,
            start_date,
            end_date,
            token,
            isPlanSelected,
            isadmin,
            passwordChanged,
            username,
            isAppInstalled,
            workspaceId,
            breakstatus,
            isteammanager
          ) =>
            login(
              uid,
              name,
              image,
              email,
              selectedPlan,
              remainingCharacters,
              totalCharacters,
              start_date,
              end_date,
              token,
              isPlanSelected,
              undefined,
              isadmin,
              passwordChanged,
              username,
              isAppInstalled,
              workspaceId,
              breakstatus,
              isteammanager
            ),
          logout: logout,
          socket,
        }}
      >
        <Routes>
          <Route
            path={PagesRoutes.AxolotlOwnerSignIn.path}
            element={
              authToken ? (
                isAxolotlOwner ? (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                ) : (
                  <Navigate to={PagesRoutes.Dashboard.path} />
                )
              ) : (
                <AxolotlOwnerSignIn />
              )
            }
          />

          <Route
            path={PagesRoutes.AxolotlOwnerDashboard.path}
            element={
              authToken ? (
                isAxolotlOwner ? (
                  <>
                    <Sidebar />
                    <main className="content mainNavContainer">
                      <Navbars />
                    </main>
                    <AxolotlDashboard />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.Dashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.SlackAuthRedirectLogin.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <Navigate to={PagesRoutes.Dashboard.path} />
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <SlackAuthRedirectLogin />
              )
            }
          />

          <Route path={PagesRoutes.Terms.path} element={<Terms />} />
          <Route path={PagesRoutes.Policy.path} element={<Policy />} />

          <Route
            path={PagesRoutes.SlackAuthRedirectInstall.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <Navigate to={PagesRoutes.Dashboard.path} />
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <SlackAuthRedirectInstall />
              )
            }
          />

          <Route
            path={PagesRoutes.HomePage.path}
            element={
              authToken ? (
                isAxolotlOwner ? (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                ) : (
                  <Navigate to={PagesRoutes.Dashboard.path} />
                )
              ) : (
                <HomePage />
              )
            }
          />

          <Route
            path={PagesRoutes.UserActivity.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Team Activity"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <UserActivityDashboard />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.TimeEditingRequests.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Tracking Request"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <TimeEditRequests
                      setPendingRequestsCount={setPendingRequestsCount}
                    />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.LiveDashboard.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Live Dashborad"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <LiveDashboard />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.HrManagement.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="HR Settings"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <HrPage />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Settings.path}
            element={
              authToken ? (
                <>
                  <Sidebar
                    unreadNotificationsCount={unreadNotificationsCount}
                    pendingRequestsCount={pendingRequestsCount}
                  />
                  <main className="content mainNavContainer">
                    <Navbars
                      label="Settings"
                      shouldShowBackButton={false}
                      unreadNotificationCount={unreadNotificationsCount}
                      setUnreadNotificationCount={setUnreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                  </main>
                  <Settings />
                </>
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Projects.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Projects"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <Projects />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.SlackUpdateStatus.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Configs"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <CustomEmoji />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Users.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Users"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <Users />{" "}
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Notifications.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Notifications"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <Notifications
                      setUnreadNotificationsCount={setUnreadNotificationsCount}
                    />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Teams.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Teams"
                        shouldShowBackButton={false}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <Teams />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.TeamDetails.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="Team Details"
                        shouldShowBackButton={true}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <TeamDetails />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.UserDetails.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  <>
                    <Sidebar
                      unreadNotificationsCount={unreadNotificationsCount}
                      pendingRequestsCount={pendingRequestsCount}
                    />
                    <main className="content mainNavContainer">
                      <Navbars
                        label="User Details"
                        shouldShowBackButton={true}
                        unreadNotificationCount={unreadNotificationsCount}
                        setUnreadNotificationCount={setUnreadNotificationsCount}
                      />
                    </main>
                    <UserDetails />
                  </>
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />

          <Route
            path={PagesRoutes.Dashboard.path}
            element={
              authToken ? (
                !isAxolotlOwner ? (
                  !isadmin ? (
                    <>
                      <Sidebar
                        unreadNotificationsCount={unreadNotificationsCount}
                        pendingRequestsCount={pendingRequestsCount}
                      />
                      <main className="content mainNavContainer">
                        <Navbars
                          label="Dashboard"
                          shouldShowBackButton={false}
                          unreadNotificationCount={unreadNotificationsCount}
                          setUnreadNotificationCount={
                            setUnreadNotificationsCount
                          }
                        />
                      </main>
                      <Dashboard />{" "}
                    </>
                  ) : (
                    <>
                      <Sidebar
                        unreadNotificationsCount={unreadNotificationsCount}
                        pendingRequestsCount={pendingRequestsCount}
                      />
                      <main className="content mainNavContainer">
                        <Navbars
                          label="Dashboard"
                          shouldShowBackButton={false}
                          unreadNotificationCount={unreadNotificationsCount}
                          setUnreadNotificationCount={
                            setUnreadNotificationsCount
                          }
                        />
                      </main>
                      <Dashboard />
                    </>
                  )
                ) : (
                  <Navigate to={PagesRoutes.AxolotlOwnerDashboard.path} />
                )
              ) : (
                <Navigate to={PagesRoutes.HomePage.path} />
              )
            }
          />
        </Routes>
      </AuthContext.Provider>
    </>
  );
};

export default App;
