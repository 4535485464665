import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "@themesberg/react-bootstrap";
import { Input, Modal, Select, Skeleton, Space, notification } from "antd";
import "chart.js/auto";
import moment from "moment";
import randomColor from "randomcolor";
import { useContext, useEffect, useState } from "react";

import CreateTask from "../../components/CreateTask";
import RangePicker from "../../components/DateRangePicker";
import DeleteTrackingTimeModal from "../../components/DeleteTrackingTimeModal";
import EditTrackingTimeModal from "../../components/EditTrackingTimeModal";
import Loader from "../../components/Loader";
import UserTask from "../../components/UserTask";
import Bar from "../../components/charts/Bar";
import { AuthContext } from "../../context/auth-context";
import { chromeExtensionId } from "../../data/constants";
import { structureDataForBarChart } from "../../helpers/project";
import * as Data from "../../helpers/server";
import { getDates, mergeTasks } from "../../helpers/utils";
import useProjectsTasks from "../../hooks/useProjectsTasks";
import TrackingRequestsHistoryModal from "./TrackingRequestsHistoryModal";

/* global chrome */

const { Search } = Input;
const barChartColor = randomColor();
const pageSize = 10;

const AdminDashboard = () => {
  const auth = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersLoading, setUsersLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [labelsForBarChart, setLabelsForBarChart] = useState([]);
  const [order, setOrder] = useState("desc");
  const [titleForBarChart, setTitleForBarChart] = useState("00:00:00");
  const [dataSetsForBarChart, setDataSetsForBarChart] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [userTasksLoading, setUserTasksLoading] = useState(false);
  const [showEditTimeModal, setShowEditTimeModal] = useState(false);
  const [selectedTrackingHistory, setSelectedTrackingHistory] = useState(null);
  const [updateRequestLoading, setUpdateRequestLoading] = useState(false);
  const [editRequestHistory, setEditRequestHistory] = useState([]);
  const [showEditRequestsModal, setShowEditRequestsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editRequestsHistoryLoading, setEditRequestsHistoryLoading] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteErr, setDeleteErr] = useState(false);

  const onSelectProject = async (id) => {
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: id,
      task: null,
      order,
      keyword,
      user: selectedUser,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: id,
      task: null,
      keyword,
      user: selectedUser,
    });
  };

  const onClearProject = async () => {
    setUserTasks([]);
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: null,
      task: null,
      order,
      keyword,
      user: selectedUser,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: null,
      task: null,
      keyword,
      user: selectedUser,
    });
  };

  const onClearTask = async () => {
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: null,
      order,
      keyword,
      user: selectedUser,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: null,
      keyword,
      user: selectedUser,
    });
  };

  const onSelectTask = async (id) => {
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: id,
      order,
      keyword,
      user: selectedUser,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: id,
      keyword,
      user: selectedUser,
    });
  };

  const {
    projects,
    projectsLoading,
    selectProject,
    clearProject,
    clearTask,
    selectTask,
    selectedProject,
    selectedTask,
    tasks,
    tasksLoading,
    updateProjects,
    updateTasks,
    setSelectedProject,
    setSelectedTask,
  } = useProjectsTasks({
    onSelectProject,
    onClearProject,
    onSelectTask,
    onClearTask,
  });

  const openNotification = (type, err, message) => {
    api[type]({
      message: err || message || "Date and time updated",
      placement: "bottomRight",
    });
  };

  const getUserActivity = async ({
    page,
    pageSize,
    startDate,
    endDate,
    project,
    order,
    task,
    keyword,
    user,
  }) => {
    try {
      if (auth?.token) {
        setUserTasksLoading(true);
        const { tasks, err } = await Data.getUserActivityReports(
          {
            page,
            pageSize,
            startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            project,
            order,
            keyword,
            task,
            user: user || auth?.userId,
          },
          auth?.token
        );
        setUserTasksLoading(false);
        if (err) return console.log(err);

        setUserTasks((prev) => prev.concat(tasks));

        if (tasks.length < pageSize) setShouldLoadMore(false);
        else setShouldLoadMore(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);

    await getUserActivity({
      page: newPage,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order,
      keyword,
      user: selectedUser,
    });
  };

  const loadMore =
    shouldLoadMore && !userTasksLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          onClick={onLoadMore}
          hidden={!shouldLoadMore && userTasksLoading}
        >
          Load next 10 tasks
        </Button>
      </div>
    ) : null;

  const getUserActivityReports = async ({
    user = auth?.userId,
    startDate,
    endDate,
    project,
    task,
    keyword,
  }) => {
    try {
      if (auth?.token) {
        setIsLoading(true);
        const { tasks, err } = await Data.getUserActivityReports(
          {
            startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
            project,
            task,
            user: user
              ? user
              : auth?.isadmin
              ? user || auth?.userId
              : auth?.userId,
            keyword,
          },
          auth?.token
        );
        setIsLoading(false);
        if (err) return console.log(err);

        const dates = getDates(startDate, endDate);
        const { dataSets, title } = structureDataForBarChart(tasks, dates);

        setTitleForBarChart(title);
        setDataSetsForBarChart(dataSets);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTimeEditRequestHistories = async (id, selectedTaskHistory) => {
    try {
      if (auth?.token) {
        setEditRequestHistory([]);
        setEditRequestsHistoryLoading(true);
        const { histories } = await Data.getTimeEditRequestHistories(
          auth?.token,
          { id },
          auth?.token
        );

        setEditRequestHistory(histories);

        setEditRequestsHistoryLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        keyword,
        user: selectedUser,
      });
      updateProjects();
      updateUsers();

      getUserActivity({
        page,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
        order,
        task: selectedTask,
        user: selectedUser,
      });
    }
  }, [auth?.token]);

  useEffect(() => {
    let interval;
    if (data && auth.latestTask && !auth.latestTask.stopped_at) {
      interval = window.setInterval(() => {
        setData((data) =>
          data.map((row) => {
            return { ...row, time: Date.now() };
          })
        );
      }, 1000);
    }

    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (auth?.token) {
      auth?.socket?.on(`task-started-${auth?.uid}`, async () => {
        setKeyword(null);
        setStartDate(moment().startOf("week"));
        setEndDate(moment().endOf("week"));
        setSelectedProject(null);
        setSelectedTask(null);

        getUserActivityReports({
          startDate,
          endDate,
          project: selectedProject,
          task: selectedTask,
          user: selectedUser,
        });
      });
      auth?.socket?.on(`task-stopped-${auth?.uid}`, async () => {
        setKeyword(null);
        setStartDate(moment().startOf("week"));
        setEndDate(moment().endOf("week"));
        setSelectedProject(null);
        setSelectedTask(null);

        getUserActivityReports({
          startDate,
          endDate,
          project: selectedProject,
          task: selectedTask,
          user: selectedUser,
        });
      });

      auth?.socket?.on(`role-updated-${auth?.uid}`, () => {
        auth?.logout();
        if (window.navigator.userAgent.toLowerCase().includes("chrome")) {
          chrome.runtime?.sendMessage(chromeExtensionId, "logout");
        }
      });
    }

    return () => {
      auth?.socket?.off(`task-started-${auth?.uid}`);
      auth?.socket?.off(`task-stopped-${auth?.uid}`);
    };
  }, [auth?.socket]);

  useEffect(() => {
    const dates = getDates(startDate, endDate);
    setLabelsForBarChart(dates);
  }, [startDate]);

  const showError = () => {
    const secondsToGo = 5;

    const modal = Modal.error({
      title: err,
      onOk: () => setErr(null),
      onCancel: () => setErr(null),
    });

    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const onSearch = async (value) => {
    setUserTasks([]);
    setPage(1);
    if (value) {
      await getUserActivity({
        page: 1,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        keyword: value,
        user: selectedUser,
      });
      await getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        keyword: value,
        user: selectedUser,
      });
    } else {
      await getUserActivity({
        page: 1,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        user: selectedUser,
      });

      await getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        user: selectedUser,
      });
    }
  };

  const onKeywordChange = async (e) => {
    setKeyword(e.target.value);
    if (!e.target.value) {
      setPage(1);
      setUserTasks([]);

      await getUserActivity({
        page: 1,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        user: selectedUser,
      });

      await getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        user: selectedUser,
      });
    }
  };

  const onDateRangeChange = async (e) => {
    setUserTasks([]);
    const range = e.currentTarget.value.split(" - ");
    const startDate = moment(range[0], "DD.MM.YYYY").startOf("day");
    const endDate = moment(range[1], "DD.MM.YYYY").endOf("day");

    setStartDate(startDate);
    setEndDate(endDate);

    await getUserActivity({
      page,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order,
      keyword,
      user: selectedUser,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      keyword,
      user: selectedUser,
    });
  };

  const selectUser = async (id) => {
    setSelectedUser(id);
    setUserTasks([]);
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order,
      keyword,
      user: id,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      keyword,
      user: id,
    });
  };

  const updateUsers = async (keyword) => {
    try {
      if (auth?.token && auth?.isadmin) {
        setUsersLoading(true);
        const { users, err } = await Data.getAllUsers(auth?.token, keyword);
        setUsersLoading(false);

        if (err) return console.log(err);
        setUsers(users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const exportAllTaskHistories = async (e) => {
    try {
      const { err, message } = await Data.exportAllTaskHistories(
        auth?.token,
        {
          keyword,
          startDate: startDate.startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          endDate: endDate.endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
          project: selectedProject,
          task: selectedTask,
          user: selectedUser,
        },
        e.currentTarget.value
      );
      if (err) return console.log(err);
      console.log(message);
    } catch (err) {
      console.log(err);
    }
  };

  const clearUser = async () => {
    setSelectedUser(null);
    setUserTasks([]);
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order,
      keyword,
      user: null,
    });

    getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      keyword,
      user: null,
    });
  };

  const changeOrder = async () => {
    const newOrder = order === "desc" ? "asc" : "desc";
    setOrder(newOrder);
    setUserTasks([]);
    setPage(1);

    await getUserActivity({
      page: 1,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order: newOrder,
      keyword,
      user: selectedUser,
    });
  };

  const startTaskTimer = async (task, project) => {
    if (auth?.token) {
      setUserTasksLoading(true);
      setIsLoading(true);

      const { message, err } = await Data.startTracking(
        { task, project },
        auth?.token
      );

      if (err) return console.log(err);

      await auth?.getUserLatestTask();

      await getUserActivity({
        page,
        pageSize,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        keyword,
        user: selectedUser,
      });
      setUserTasksLoading(false);

      await getUserActivityReports({
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        keyword,
        user: selectedUser,
      });
      setIsLoading(false);
    }
  };

  const stopTaskTimer = async () => {
    setIsLoading(true);
    setUserTasksLoading(true);
    const { err } = await Data.stopTracking(auth?.token, {
      reqUser: selectedUser,
    });

    if (err) return console.log(err);

    await auth?.getUserLatestTask();

    await getUserActivity({
      page,
      pageSize,
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      order,
      keyword,
      user: selectedUser,
    });
    setUserTasksLoading(false);

    await getUserActivityReports({
      startDate,
      endDate,
      project: selectedProject,
      task: selectedTask,
      keyword,
      user: selectedUser,
    });
    setIsLoading(false);
  };

  const showEditTimeEntryModal = (e) => {
    setShowEditTimeModal(true);
    const id = e.currentTarget.id;
    const trackingHistoryId = id.split("__")[1];
    const date = id.split("__")[0];

    let flag = 0;
    for (let i = 0; i < userTasks.length; i++) {
      if (userTasks[i].formatted_started_at === date) {
        for (let j = 0; j < userTasks[i].tasks.length; j++) {
          if (userTasks[i].tasks[j].taskhistoryid == trackingHistoryId) {
            setSelectedTrackingHistory(userTasks[i].tasks[j]);
            flag = 1;
            break;
          }
        }
      }

      if (flag === 1) {
        break;
      }
    }
  };

  const showEditRequestsHistoryModal = async (e) => {
    setShowEditRequestsModal(true);
    const id = e.currentTarget.id;
    const trackingHistoryId = id.split("__")[1];
    const date = id.split("__")[0];
    let selectedTaskHistory;

    let flag = 0;
    for (let i = 0; i < userTasks.length; i++) {
      if (userTasks[i].formatted_started_at === date) {
        for (let j = 0; j < userTasks[i].tasks.length; j++) {
          if (userTasks[i].tasks[j].taskhistoryid == trackingHistoryId) {
            selectedTaskHistory = userTasks[i].tasks[j];
            setSelectedTrackingHistory(userTasks[i].tasks[j]);
            flag = 1;
            break;
          }
        }
      }

      if (flag === 1) {
        break;
      }
    }

    await getTimeEditRequestHistories(trackingHistoryId, selectedTaskHistory);
  };

  const openDeleteModal = (e) => {
    setShowDeleteModal(true);
    const id = e.currentTarget.id;
    const trackingHistoryId = id.split("__")[1];
    const date = id.split("__")[0];

    let flag = 0;
    for (let i = 0; i < userTasks.length; i++) {
      if (userTasks[i].formatted_started_at === date) {
        for (let j = 0; j < userTasks[i].tasks.length; j++) {
          if (userTasks[i].tasks[j].taskhistoryid == trackingHistoryId) {
            setSelectedTrackingHistory({
              ...userTasks[i].tasks[j],
              formatted_started_at: userTasks[i].formatted_started_at,
            });
            flag = 1;
            break;
          }
        }
      }

      if (flag === 1) {
        break;
      }
    }
  };

  const closeEditTimeEntryModal = () => {
    setShowEditTimeModal(false);
    setSelectedTrackingHistory(null);
  };

  const closeEditRequestsHistoryModal = () => {
    setShowEditRequestsModal(false);
    setSelectedTrackingHistory(null);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedTrackingHistory(null);
    setDeleteErr(null);
  };

  const updateTrackingTime = async (values) => {
    let errMsg = "";
    let flag = 0;
    let started_at;
    let stopped_at;

    setUpdateRequestLoading(true);

    if (
      moment(values.originalstarttime).format("HH:mm") !==
      moment(values.starttime).format("HH:mm")
    ) {
      flag = 1;
      const date = moment(values.date);
      const date1 = moment(values.starttime, "HH:mm");

      date.set("hours", date1.get("hours"));
      date.set("minutes", date1.get("minutes"));
      date.set("seconds", date1.get("seconds"));
      started_at = date.toISOString();
    }

    if (
      moment(values.originalstoptime).format("HH:mm") !==
      moment(values.stoptime).format("HH:mm")
    ) {
      flag = 1;
      const date = moment(values.date);

      const date1 = moment(values.stoptime, "HH:mm");

      date.set("hours", date1.get("hours"));
      date.set("minutes", date1.get("minutes"));
      date.set("seconds", date1.get("seconds"));

      stopped_at = date.toISOString();
    }

    if (
      ((moment(
        selectedTrackingHistory.updated_started_at ||
          selectedTrackingHistory.th_original_started_at ||
          selectedTrackingHistory.started_at
      ).format("YYYY-MM-DD") !== values.date.clone().format("YYYY-MM-DD") ||
        moment(
          selectedTrackingHistory.updated_stopped_at ||
            selectedTrackingHistory.th_original_stopped_at ||
            selectedTrackingHistory.stopped_at
        ).format("YYYY-MM-DD") !== values.date.clone().format("YYYY-MM-DD")) &&
        !started_at &&
        !stopped_at) ||
      moment(values.originaldate).format("YYYY-MM-DD") !==
        moment(values.date).format("YYYY-MM-DD")
    ) {
      flag = 1;
      const startDate = moment(values.date.clone());
      const originalStartDate = moment(values.starttime);

      startDate.set("hours", originalStartDate.get("hours"));
      startDate.set("minutes", originalStartDate.get("minutes"));
      startDate.set("seconds", originalStartDate.get("seconds"));
      startDate.set("milliseconds", originalStartDate.get("milliseconds"));

      const endDate = moment(values.date.clone());
      const originalEndDate = moment(values.stoptime);

      endDate.set("hours", originalEndDate.get("hours"));
      endDate.set("minutes", originalEndDate.get("minutes"));
      endDate.set("seconds", originalEndDate.get("seconds"));
      endDate.set("milliseconds", originalEndDate.get("milliseconds"));
      started_at = startDate.toISOString();
      stopped_at = endDate.toISOString();
    }

    const formData = {
      taskhistory: selectedTrackingHistory.taskhistoryid,
      project: selectedTrackingHistory.projectid,
      task: selectedTrackingHistory.taskid,
      user: selectedTrackingHistory.userId,
    };
    if (started_at) formData.started_at = started_at;
    if (stopped_at) formData.stopped_at = stopped_at;

    const { err } = await Data.updateTrackingTime(formData, auth?.token);
    errMsg = err;

    setUpdateRequestLoading(false);
    setShowEditTimeModal(false);

    if (
      (!selectedTrackingHistory.needtimeeditingapproval || auth?.isadmin) &&
      flag === 1
    ) {
      setUserTasks([]);
      await getUserActivity({
        page: 1,
        pageSize: userTasks.length,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        keyword,
        user: selectedUser,
      });
      openNotification(errMsg ? "error" : "info", errMsg, "Time Updated!");
    } else if (flag === 1) {
      setUserTasks([]);
      await getUserActivity({
        page: 1,
        pageSize: userTasks.length,
        startDate,
        endDate,
        project: selectedProject,
        task: selectedTask,
        order,
        keyword,
        user: selectedUser,
      });
      openNotification(errMsg ? "error" : "info", errMsg, "Request Created!");
    }
  };

  const deleteTrackingTime = async (e) => {
    const trackingHistoryId = e.currentTarget.value;

    try {
      setDeleteLoading(true);
      const { err } = await Data.deleteTrackedTime(
        trackingHistoryId,
        auth?.token
      );
      if (!err) {
        setUserTasks((userTasks) =>
          userTasks?.map((userTask) => ({
            ...userTask,
            tasks: userTask.tasks?.filter(
              (task) =>
                task.taskhistoryid !== +trackingHistoryId ||
                (task.references_to &&
                  task.references_to !== +trackingHistoryId)
            ),
          }))
        );
        getUserActivityReports({
          startDate,
          endDate,
          project: selectedProject,
          task: selectedTask,
          keyword,
          user: selectedUser,
        });
        setShowDeleteModal(false);
      }
      setDeleteLoading(false);
      setDeleteErr(err);
    } catch (err) {
      setDeleteLoading(true);
      console.log(err);
      setDeleteErr(err.message || JSON.stringify(err));
    }
  };

  return (
    <>
      <main className="content container-new">
        {contextHolder}
        {err && showError()}
        {showEditTimeModal && (
          <EditTrackingTimeModal
            loading={updateRequestLoading}
            selectedTrackingHistory={selectedTrackingHistory}
            showModal={showEditTimeModal}
            onOk={updateTrackingTime}
            onCancel={closeEditTimeEntryModal}
          />
        )}

        {showEditRequestsModal && (
          <TrackingRequestsHistoryModal
            histories={editRequestHistory}
            loading={editRequestsHistoryLoading}
            selectedTrackingHistory={selectedTrackingHistory}
            showModal={showEditRequestsModal}
            onCancel={closeEditRequestsHistoryModal}
          />
        )}

        {showDeleteModal && (
          <DeleteTrackingTimeModal
            loading={deleteLoading}
            selectedTrackingHistory={selectedTrackingHistory}
            showModal={showDeleteModal}
            onCancel={closeDeleteModal}
            onOk={deleteTrackingTime}
            err={deleteErr}
          />
        )}

        <div style={{ marginBottom: "4px" }}>
          <Search
            value={keyword}
            placeholder="Search"
            onSearch={onSearch}
            onChange={onKeywordChange}
            style={{ width: "130px" }}
          />
          <Space style={{ float: "right" }}>
            <Select
              allowClear
              onClear={clearProject}
              value={selectedProject}
              showSearch
              dropdownMatchSelectWidth={false}
              placeholder="Select Project"
              onSearch={updateProjects}
              onSelect={selectProject}
              filterOption={false}
              options={projects}
              loading={projectsLoading}
              style={{ minWidth: "150px", backgroundColor: "white" }}
            ></Select>
            <Select
              value={selectedTask}
              onClear={clearTask}
              showSearch
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              placeholder="Select Task"
              onSearch={(keyword) => updateTasks(selectedProject, keyword)}
              onSelect={selectTask}
              filterOption={false}
              loading={tasksLoading}
              disabled={!selectedProject}
              style={{ minWidth: "150px", backgroundColor: "white" }}
              options={tasks}
              allowClear
            ></Select>
            <Select
              value={selectedUser}
              onClear={clearUser}
              showSearch
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              placeholder="Select User"
              onSearch={updateUsers}
              onSelect={selectUser}
              filterOption={false}
              loading={usersLoading}
              style={{ minWidth: "150px", backgroundColor: "white" }}
              options={users}
              allowClear
            ></Select>
            <RangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={onDateRangeChange}
            />
            <Button
              value="csv"
              onClick={exportAllTaskHistories}
              hidden={!auth?.isadmin}
            >
              Download CSV
            </Button>

            <Button
              value="xlsx"
              onClick={exportAllTaskHistories}
              hidden={!auth?.isadmin}
            >
              Download Excel
            </Button>
          </Space>
        </div>

        {isLoading && <Loader />}

        {!isLoading && (
          <>
            <div
              className=""
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                padding: "0px 10px",
                marginTop: "10px",
                boxShadow:
                  "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
              }}
            >
              <>
                <p
                  style={{
                    color: "#17273A",
                    fontSize: "14px",
                    marginLeft: "15px",
                    marginTop: "10px",
                  }}
                >
                  <b>Your Activity</b>
                </p>
                <Bar
                  labels={labelsForBarChart}
                  title={""}
                  label={"Working Hours"}
                  data={dataSetsForBarChart}
                  dataSets={dataSetsForBarChart}
                  backgroundColor={barChartColor}
                />
                <p
                  style={{
                    color: "#17273A",
                    fontSize: "14px",
                    marginTop: "17px",
                    marginLeft: "40px",
                  }}
                >
                  Total Tracked {startDate.format("DD MMM YYYY")}&nbsp;-&nbsp;
                  {endDate.format("DD MMM YYYY")}:&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>{titleForBarChart} </b>
                </p>
              </>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "5px",
                  padding: "0px 10px",
                  margin: "10px 0px",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "#17273A",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    My Tasks
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={changeOrder}
                    style={{
                      border: "none",
                      color: "#2664f2",
                      background: "none",
                    }}
                  >
                    Sort by date
                    {order === "desc" && (
                      <DownOutlined
                        style={{
                          verticalAlign: "middle",
                          fontSize: "15px",
                          padding: "0px 2px",
                        }}
                      />
                    )}
                    {order === "asc" && (
                      <UpOutlined
                        style={{
                          verticalAlign: "middle",
                          fontSize: "15px",
                          padding: "0px 2px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
              <CreateTask />
              {userTasksLoading && <Skeleton></Skeleton>} 
              {userTasks?.map((userTask, index) => {
                const { tasks, mergedTasks } = mergeTasks(userTask.tasks);
                return (
                  <UserTask
                    {...userTask}
                    index={index}
                    startTaskTimer={startTaskTimer}
                    stopTaskTimer={stopTaskTimer}
                    showEditTimeEntryModal={showEditTimeEntryModal}
                    showEditRequestsHistoryModal={showEditRequestsHistoryModal}
                    closeEditTimeEntryModal={closeEditTimeEntryModal}
                    openDeleteModal={openDeleteModal}
                    tasks={tasks}
                    mergedTasks={mergedTasks}
                  />
                );
              })}
              {loadMore}
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default AdminDashboard;
