import { Button, Card } from "@themesberg/react-bootstrap";
import { useContext, useState } from "react";

import UpdatePassword from "../../components/UpdatePassword";
import UpdateProfile from "../../components/UpdateProfile";
import UpdateUserConfigs from "../../components/UpdateUserConfigs";
import UpdateWorkspaceConfigs from "../../components/UpdateWorkspaceConfigs";
import { AuthContext } from "../../context/auth-context";
import DeleteUserAccountModal from "./DeleteUserAccountModal";
import UninstallAppModal from "./UninstallAppModal";

const Settings = () => {
  const isAxolotlOwner = localStorage.getItem("isAxolotlOwner") === "true";
  const auth = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUninstallModal, setShowUninstallModal] = useState(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const openUninstallModal = () => {
    setShowUninstallModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeUninstallModal = () => {
    setShowUninstallModal(false);
  };

  if (isAxolotlOwner)
    return (
      <main className="content container-new">
        <div className="container userSetting">
          <div className="row">
            {isAxolotlOwner && (
              <div className="col-md-auto">
                <Card style={{ border: "none" }}>
                  <Card.Body>
                    <UpdatePassword />
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        </div>
      </main>
    );

  return (
    <>
      {showDeleteModal && (
        <DeleteUserAccountModal
          showModal={showDeleteModal}
          onModalClose={closeDeleteModal}
          auth={auth}
        />
      )}

      {showUninstallModal && (
        <UninstallAppModal
          showModal={showUninstallModal}
          onModalClose={closeUninstallModal}
          auth={auth}
        />
      )}

      <main className="content container-new">
        <div className="container userSetting">
          <div className="row">
            {isAxolotlOwner && (
              <div className="col-md-auto">
                <Card style={{ border: "none" }}>
                  <Card.Body>
                    <UpdatePassword />
                  </Card.Body>
                </Card>
              </div>
            )}

            <div className="col-md-auto">
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <UpdateProfile />
                </Card.Body>
              </Card>
            </div>
            <div className="col-5">
              <Card style={{ border: "none" }}>
                <Card.Body>
                  <UpdateUserConfigs />
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-md-auto">
              <Card style={{ border: "none" }}>
                <Card.Body>
                  {auth?.isadmin && <UpdateWorkspaceConfigs />}
                </Card.Body>
              </Card>
            </div>
          </div>
          <Button
            variant="danger"
            style={{ marginLeft: "20px" }}
            onClick={openDeleteModal}
          >
            Delete My Account
          </Button>

          <Button
            variant="danger"
            style={{ marginLeft: "20px" }}
            onClick={openUninstallModal}
          >
            Uninstall axolotl from Slack
          </Button>
        </div>
      </main>
    </>
  );
};

export default Settings;
